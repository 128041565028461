import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {useCreate, LoadingIndicator, useDataProvider} from 'react-admin';
import {getCleanHydraId} from "../../utils/HydraUtils";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";

export const TableOptionSalesByYear = (props) => {
    const {options, from, to} = props;
    const [create, {data, isLoading, error}] = useCreate();
    const dataProvider = useDataProvider();
    const [years, setYears] = useState([]);


    const handleDownload = () => {
        dataProvider.create('reports/sales/options-by-year', {
            data: {
                yearFrom: from.getFullYear(),
                yearTo: to.getFullYear(),
                type: 'xslx',
                options: options.map(o => {
                    return {
                        "id": parseInt(getCleanHydraId(o.equipment.id)),
                        "block": o.block.label,
                        "option": o.equipment.label
                    }
                })
            }
        }).then(({data}) => {
            const base64 = JSON.parse(data.result);
            download(base64, 'options-sales-by-year.xlsx')
        })
    }

    function download(content, fileName) {
        var link = document.createElement("a");
        link.href = content;
        link.download = fileName;
        link.click();
    }

    useEffect(() => {
        const years = (new Array(to.getFullYear() - from.getFullYear() + 1)).fill(0).map((v, i) => {
            return from.getFullYear() + i
        })
        setYears(years);
    }, [from, to]);

    useEffect(() => {
        create('reports/sales/options-by-year', {
            data: {
                yearFrom: from.getFullYear(),
                yearTo: to.getFullYear(),
                options: options.map(o => {
                    return {
                        "id": parseInt(getCleanHydraId(o.equipment.id)),
                        "block": o.block.label,
                        "option": o.equipment.label
                    }
                })
            }
        })
    }, [from, to, options.length]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    if (undefined === data || !data.result || data.result.length === 0) {
        return <div/>
    }

    return (
        <React.Fragment>
            {(data.result.length > 0) ?
                <Box sx={{width: '80%', marginTop: '15px', paddingBottom: '15px'}}>
                    <Button startIcon={<SaveIcon/>} onClick={handleDownload}>Сохранить отчет</Button>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="simple table" size="small">
                            {
                                data.options.map(o => {
                                    let total = {};
                                    years.forEach(y => {
                                        total[y] = 0;
                                    })
                                    return (
                                        <React.Fragment key={o.id}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>Опция</strong></TableCell>
                                                    <TableCell><strong>{o.option}</strong></TableCell>
                                                    {
                                                        years.map(y => <TableCell align="right"
                                                                                  key={y}><strong>{y}</strong></TableCell>)
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    data.result.find(r => r.option.id === o.id).models.map(m => {
                                                        return (
                                                            <TableRow key={m.id}>
                                                                <TableCell>{m.brand}</TableCell>
                                                                <TableCell>{m.model}</TableCell>
                                                                {
                                                                    years.map(y => {
                                                                        total[y] += m.sales[y];
                                                                        return (
                                                                            <TableCell align="right" key={m.id + '_' + y}>
                                                                                { m.sales[y]}
                                                                            </TableCell>
                                                                        )
                                                                    })
                                                                }
                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                                <TableRow>
                                                    <TableCell><strong>Итого</strong></TableCell>
                                                    <TableCell />
                                                    {
                                                        years.map(y => {
                                                            return (
                                                                <TableCell align="right" key={o.id + '_total_' + y}>
                                                                    <strong>{ total[y]}</strong>
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            </TableBody>
                                        </React.Fragment>
                                    )
                                })
                            }

                        </Table>
                    </TableContainer>
                </Box> : <div/>
            }

        </React.Fragment>
    )
}