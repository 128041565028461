import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {useCreate, LoadingIndicator, useDataProvider} from 'react-admin';
import {getCleanHydraId} from "../../utils/HydraUtils";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";

export const TableSalesByYear = (props) => {
    const {options, from, to} = props;
    const [create, {data, isLoading, error}] = useCreate();
    const dataProvider = useDataProvider();
    const [years, setYears] = useState([]);

    const handleDownload = () => {
        dataProvider.create('reports/sales/by-year', {
            data: {
                yearFrom: from.getFullYear(),
                yearTo: to.getFullYear(),
                type: 'xslx',
                options: options.map(o => {
                    return {
                        brand: parseInt(getCleanHydraId(o.brand.id)),
                        model: parseInt(getCleanHydraId(o.model.id)),
                        engine: o.engine?.id,
                        drive: o.drive?.id,
                        versions: Array.isArray(o.versions) ? o.versions.map(v => parseInt(getCleanHydraId(v.id))) : []
                    }
                })
            }
        }).then(({data}) => {
            const base64 = JSON.parse(data.result);
            download(base64, 'sales-by-year.xlsx')
        })
    }

    function download(content, fileName) {
        var link = document.createElement("a");
        link.href = content;
        link.download = fileName;
        link.click();
    }

    useEffect(() => {
        const years = (new Array(to.getFullYear() - from.getFullYear() + 1)).fill(0).map((v, i) => {
            return from.getFullYear() + i
        })
        setYears(years);
    }, [from, to]);

    useEffect(() => {
        create('reports/sales/by-year', {
            data: {
                yearFrom: from.getFullYear(),
                yearTo: to.getFullYear(),
                options: options.map(o => {
                    return {
                        brand: parseInt(getCleanHydraId(o.brand.id)),
                        model: parseInt(getCleanHydraId(o.model.id)),
                        engine: o.engine?.id,
                        drive: o.drive?.id,
                        versions: Array.isArray(o.versions) ? o.versions.map(v => parseInt(getCleanHydraId(v.id))) : []
                    }
                })
            }
        })
    }, [from, to, options.length]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    if (undefined === data || !data.result || data.result.length === 0) {
        return <div/>
    }

    return (
        <React.Fragment>
            {(data.result.length > 0) ?
                <Box sx={{width: '80%', marginTop: '15px', paddingBottom: '15px'}}>
                    <Button startIcon={<SaveIcon/>} onClick={handleDownload}>Сохранить отчет</Button>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Модель</TableCell>
                                    <TableCell>Версия</TableCell>
                                    {
                                        years.map(y => <TableCell align="right" key={y}>{y}</TableCell>)
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.result.map((d) => (
                                    <>
                                        <TableRow
                                            key={d.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                <strong>{d.name}</strong>
                                            </TableCell>
                                            <TableCell/>
                                            {
                                                years.map(y => {
                                                    let total = 0;
                                                    d.versions.map(v => {
                                                        total = total + v.sales[y];
                                                    })
                                                    return <TableCell key={d.id} align="right" component="th" scope="row"><strong>{total}</strong></TableCell>;
                                                })
                                            }
                                        </TableRow>
                                        {
                                            d.versions.map(v =>
                                                <TableRow
                                                    key={v.id}
                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                >
                                                    <TableCell/>
                                                    <TableCell scope="row">
                                                        {v.name}
                                                    </TableCell>
                                                    {
                                                        years.map(y => <TableCell key={v.id + '-' + y} align="right" scope="row">{v.sales[y]}</TableCell>)
                                                    }
                                                </TableRow>
                                            )
                                        }
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box> : <div/>
            }

        </React.Fragment>
    )
}