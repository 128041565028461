import { Layout } from 'react-admin';
import {CustomMenu} from "./CustomMenu";
import {CustomAppBar} from "./CustomAppBar";

const MyLayout = (props) => <Layout
    {...props}
    menu={CustomMenu}
    appBar={CustomAppBar}
/>;

export default MyLayout;