import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState} from "react";
import {useGetList} from 'react-admin';

export const SelectorBrand = (props) => {
    const {onChange} = props;

    const {data, total, isLoading, error} = useGetList(
        'brands',
        {
            pagination: {page: 1, perPage: 100},
            sort: {field: 'name', order: 'ASC'},
            filter: {isActive: true, isVirtual: false}
        }
    );

    if (error) {
        return <p>Ошибка загрузки марок</p>;
    }


    return (
        <Autocomplete
            disablePortal
            size="small"
            options={isLoading ? [] : data.map(item => ({label: item.name, id: item.id}))}
            sx={{width: '100%'}}
            renderInput={(params) => <TextField  InputProps={{ style: { fontSize: 8 } }} {...params} label={isLoading ? "Загрузка списка марок" : "Марка"}/>}
            onChange={(event, value) => {
                onChange(value);
            }}
        />
    )
}