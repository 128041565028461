import { useState, useEffect } from 'react';
import {v4 as uuidv4} from "uuid";

function useSelectorOptions() {
    const [options, setOptions] = useState([]);

    const addOption = (option) => {
        const newOptions = [...options];
        option.id = uuidv4();
        newOptions.push(option);
        setOptions(newOptions);
    }

    const deleteOption = (option) => {
        const newOptions = options.filter(o => o.id !== option.id);
        setOptions(newOptions);
    }

    return [options, addOption, deleteOption]
}

export default useSelectorOptions;