import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {Typography} from "@mui/material";

export const EquipmentOption = (props) => {
    const {deleteOption, option} = props;
    
    return (
        <Box sx={{width: '100%', marginTop: '15px', paddingBottom: '15px', borderBottom: '1px solid'}}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography variant="body2" gutterBottom>{option.block.label}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" gutterBottom>{option.equipment.label}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Button size="small" variant="contained" color="error" onClick={deleteOption}>Удалить</Button>
                </Grid>
            </Grid>
        </Box>
    )
}