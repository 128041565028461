import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {useState} from "react";
import {Typography} from "@mui/material";
import {Selector} from "../Selectors/Selector";
import {Option} from "../Selectors/Option";
import {v4 as uuidv4} from 'uuid';
import {ChartPriceLadder} from "./ChartPriceLadder";
import CustomPaper from "../../Layout/CustomPaper";
import useSelectorOptions from "../../hooks/useSelectorOptions";

export const PriceLadder = (props) => {
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [awp, setAwp] = useState(true);
    const [options, addOption, deleteOption] = useSelectorOptions();

    return (
        <Box sx={{width: '100%'}}>
            <Stack spacing={2}>
                <CustomPaper>
                    <Typography variant="h5" component='h5'>Price Ladder по комплектациям</Typography>
                    <Selector addOption={(option) => addOption(option)} setTo={setTo} setFrom={setFrom} to={to} from={from} awp={awp} setAwp={setAwp} />
                    {
                        options.map((option) => <Option key={option.id} option={option} deleteOption={() => deleteOption(option)}/>)
                    }
                </CustomPaper>
                <CustomPaper>
                    <ChartPriceLadder options={options} from={from} to={to} awp={awp} />
                </CustomPaper>
            </Stack>
        </Box>
    )
}