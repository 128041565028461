import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {useState} from "react";
import {Typography} from "@mui/material";
import CustomPaper from "../../Layout/CustomPaper";
import useSelectorOptions from "../../hooks/useSelectorOptions";
import {SelectorEquipmentByYear} from "../Selectors/SelectorEquipmentByYear";
import {EquipmentOption} from "../Selectors/EquipmentOption";
import {TableOptionSalesByYear} from "./TableOptionSalesByYear";

export const OptionSalesByYear = (props) => {
    const [from, setFrom] = useState((new Date()));
    const [to, setTo] = useState((new Date()));
    const [options, addOption, deleteOption] = useSelectorOptions();

    return (
        <Box sx={{width: '100%'}}>
            <Stack spacing={2}>
                <CustomPaper>
                    <Typography variant="h5" component='h5'>Статистика продаж опций по годам</Typography>
                    <SelectorEquipmentByYear addOption={(option) => addOption(option)} setTo={setTo} setFrom={setFrom} to={to} from={from} />
                    {
                        options.map((option) => <EquipmentOption key={option.id} option={option} deleteOption={() => deleteOption(option)}/>)
                    }
                </CustomPaper>
                <CustomPaper>
                    <TableOptionSalesByYear options={options} from={from} to={to} />
                </CustomPaper>
            </Stack>
        </Box>
    )
}