import * as React from 'react';
import {useState} from "react";
import Box from "@mui/material/Box";
import {SelectorBrand} from "./SelectorBrand";
import {SelectorModel} from "./SelectorModel";
import Grid from '@mui/material/Grid';
import {SelectorVersion} from "./SelectorVersion";
import Button from '@mui/material/Button';
import {SelectorDrive} from "./SelectorDrive";
import {SelectorEngine} from "./SelectorEngine";
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {SelectorEquipmentBlock} from "./SelectorEquipmentBlock";
import {SelectorEquipment} from "./SelectorEquipment";

export const SelectorEquipmentByYear = (props) => {
    const {addOption, setTo, setFrom, to, from} = props;
    const [block, setBlock] = useState(null);
    const [equipment, setEquipment] = useState(null);


    const onAddClick = () => {
        const option = {
            block,
            equipment,
        }

        addOption(option);
        setEquipment(null);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{width: '100%', marginTop: '30px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <DatePicker
                            views={['year']}
                            label="Начало периода"
                            minDate={new Date('2015-01-01')}
                            maxDate={new Date()}
                            value={from}
                            onChange={(newValue) => {
                                setFrom(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} size="small" helperText={null}/>}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DatePicker
                            views={['year']}
                            label="Окончание периода"
                            minDate={new Date('2015-01-01')}
                            maxDate={new Date()}
                            value={to}
                            onChange={(newValue) => {
                                setTo(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} size="small" helperText={null}/>}
                        />
                    </Grid>
                    <Grid item xs={8} />

                    <Grid item xs={2}>
                        <SelectorEquipmentBlock onChange={(block) => setBlock(block)}/>
                    </Grid>
                    <Grid item xs={2}>
                        {block && <SelectorEquipment block={block} onChange={(equipment) => setEquipment(equipment)}/>}
                    </Grid>
                    <Grid item xs={1}>
                        {equipment && <Button variant="contained" onClick={onAddClick}>Добавить</Button>}
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    )
}