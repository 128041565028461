import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useState} from "react";
import {useGetList} from "react-admin";

export const SelectorModel = (props) => {
    const {brand, onChange} = props;

    const { data, total, isLoading, error } = useGetList(
        'models',
        {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'ord', order: 'ASC' },
            //filter: { isShown: true, isArchive: false, brand: brand.id}
            filter: { brand: brand.id}
        }
    );

    if (error) { return <p>Ошибка загрузки моделей</p>; }

    return (
        <Autocomplete
            disablePortal
            size="small"
            options={isLoading ? [] : data.sort((a, b) => {
                if (a.isArchive === b.isArchive) {
                    return 0;
                }
                if (a.isArchive === true && b.isArchive === false) {
                    return 1;
                }
                if (a.isArchive === false && b.isArchive === true) {
                    return -1;
                }
            }).map(item => ({label: item.isArchive ? 'Архив: ' + item.name : item.name, id: item.id}))}
            sx={{width: '100%'}}
            renderInput={(params) => <TextField {...params} label={isLoading ? "Загрузка списка моделей" : "Модель"}/>}
            onChange={(event, value) => {
                onChange(value);
            }}
        />
    )
}