import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useState} from "react";
import {useGetList} from "react-admin";

export const SelectorEngine = (props) => {
    const {onChange} = props;
    const { data, total, isLoading, error } = useGetList(
        'reference-books',
        {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'ord', order: 'ASC' },
            filter: { sysName: 'engine-type'}
        }
    );

    if (error) { return <p>Ошибка загрузки типов двигателя</p>; }

    return (
        <Autocomplete
            disablePortal
            size="small"
            options={isLoading ? [] : data[0].referenceBookItems.map(item => ({label: item.name, id: item.id}))}
            sx={{width: '100%'}}
            renderInput={(params) => <TextField {...params} label={isLoading ? "Загрузка списка типов двигателя" : "Тип двигателя"}/>}
            onChange={(event, value) => {
                onChange(value);
            }}
        />
    )
}