import * as React from 'react';
import { AppBar } from 'react-admin';
import logo from '../assets/logo.gif';
import {
    Box,
    Typography,
} from '@mui/material';

const Logo = props => <img src={logo} alt="logo" height='46px' />;

export const CustomAppBar = props => {

    return (
        <AppBar
            style={{ backgroundColor: '#FFDF00', height:'46px' }}
            {...props}
            userMenu={false}
        >
            <Logo />
            <Box component="span" sx={{ flex: 1 }} />
        </AppBar>
    );
}