import { defaultTheme } from 'react-admin';

const theme = {
    ...defaultTheme,
    palette: {
        primary: {
            main: '#38a9b4'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
        },
    }
};

export default theme;