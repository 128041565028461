import * as React from 'react';
import {useState} from "react";
import Box from "@mui/material/Box";
import {SelectorBrand} from "./SelectorBrand";
import {SelectorModel} from "./SelectorModel";
import Grid from '@mui/material/Grid';
import {SelectorVersion} from "./SelectorVersion";
import Button from '@mui/material/Button';
import {SelectorDrive} from "./SelectorDrive";
import {SelectorEngine} from "./SelectorEngine";
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export const SelectorByYear = (props) => {
    const {addOption, setTo, setFrom, to, from} = props;
    const [brand, setBrand] = useState(null);
    const [model, setModel] = useState(null);
    const [versions, setVersions] = useState(null);
    const [drive, setDrive] = useState(null);
    const [engine, setEngine] = useState(null);


    const onAddClick = () => {
        const option = {
            brand,
            model,
            versions,
            drive,
            engine,
        }

        addOption(option);
        setModel(null);
        setVersions(null);
        setDrive(null);
        setEngine(null);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{width: '100%', marginTop: '30px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <DatePicker
                            views={['year']}
                            label="Начало периода"
                            minDate={new Date('2015-01-01')}
                            maxDate={new Date()}
                            value={from}
                            onChange={(newValue) => {
                                setFrom(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} size="small" helperText={null}/>}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DatePicker
                            views={['year']}
                            label="Окончание периода"
                            minDate={new Date('2015-01-01')}
                            maxDate={new Date()}
                            value={to}
                            onChange={(newValue) => {
                                setTo(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} size="small" helperText={null}/>}
                        />
                    </Grid>
                    <Grid item xs={8} />

                    <Grid item xs={2}>
                        <SelectorBrand onChange={(brand) => setBrand(brand)}/>
                    </Grid>
                    <Grid item xs={2}>
                        {brand && <SelectorModel brand={brand} onChange={(model) => setModel(model)}/>}
                    </Grid>
                    <Grid item xs={3}>
                        {model && <SelectorVersion model={model} onChange={(versions) => setVersions(versions)}/>}
                    </Grid>
                    <Grid item xs={2}>
                        {model && <SelectorDrive onChange={(drive) => setDrive(drive)}/>}
                    </Grid>
                    <Grid item xs={2}>
                        {model && <SelectorEngine onChange={(engine) => setEngine(engine)}/>}
                    </Grid>
                    <Grid item xs={1}>
                        {model && <Button variant="contained" onClick={onAddClick}>Добавить</Button>}
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    )
}